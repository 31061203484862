import React, { useState, useEffect } from 'react';
// Tools
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
/* import { useHistory } from "react-router-dom"; */
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
// import Swal from 'sweetalert2';
// API calls
import { getAchievementByEnterprise } from '../Models/Catalog_model';
import { 
    getActiveProductsByEnterprise,
    getBranchesByUserRol,
    getBranches
} from '../Models/Enterprise_model';
import { getJwt } from "../../../lib/auth";
import LoaderApp from '../LoaderApp';
import { connect } from 'react-redux';
import { validateAccess, getSubsection } from '../../helpers/validations';
import { useHistory } from "react-router-dom";
import $ from "jquery";
// TODO: Complementary Evidence import
import {
    getComplementaryEvidenceByEnterprise,
} from '../Models/ComplaintModel'
import ComEviCtrl from './complementarySrc/ComEviCtrl'
// import ModalViewPdf from '../enterprises/ModalViewPdf';
import ModalView2Pdf from '../enterprises/ModalView2Pdf';
import ImagesViewer from '../ImagesViewer';
import DocumentModalCompliance from '../documentManagement/DocumentModalCompliance';


const Cumplimiento = ({ access }) => {
    // General variables
    const [listAchieve, setListAchieve] = useState([]);
    /* const [catModules, setCatModules] = useState([]); */
    const [percentage, setPercentage] = useState(0);
    const [listProducts, setListProducts] = useState([]);
    /* const history = useHistory(); */
    const [thereAreProducts, setThereAreProducts] = useState(false);
    const [noInformationMessage, setNoInformationMessage] = useState(false);
    const [productData, setProductData] = useState({
        idProduct: 0,
        idCategory: 0,
        idSubcategory: 0
    });
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [disableActions, setDisableActions] = useState(false)
    const [urlShowPdf, setUrlShowPdf] = useState("")
    const [showImage, setShowImage] = useState("");
    const [listDocuments, setListDocuments] = useState([])
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState(null);
    const [idAchievement, setIdAchievement] = useState(null);
    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && access.access.list.length > 0) {
            //console.log(access)
            //console.log(validateAccess(access.access.list, 2))
            const section = validateAccess(access.access.list, 2)
            //console.log(getSubsection(section[0].subSection, 14))
            const subSection = getSubsection(section[0].subSection, 18)
            if (section[0].id_action === 0) {
                history.push("/inicio-colaborador")
            } else {
                if (subSection[0].id_action === 0) {
                    toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    history.push("/inicio-administrador")
                } else {
                    if (subSection[0].id_action === 2) {
                        setDisableActions(true)
                    }
                }
            }
        }
    }, [access, history])

    // TODO: Complementary Evidence var
    const [service, setService] = useState(1)
    const [evidence, setEvidence] = useState([])
    // TODO: Complementary Evidence useEffect
    useEffect(() => {
        getComplementaryEvidenceByEnterprise(parseInt(getJwt('enterprise')), 1, 1, 1).then(response => {
            if (response) {
                if (response.code === 1) {
                    /* let arr = []
                    response.listComplementaryEvidence.forEach(data => {
                        if (data.resourceType === 1) {
                            arr.push({ ...data, resourceName: 'Archivo' })
                        }
                        if (data.resourceType === 2) {
                            arr.push({ ...data, resourceName: 'Audio' })
                        }
                        if (data.resourceType === 3) {
                            arr.push({ ...data, resourceName: 'Video' })
                        }
                    })
                    setEvidence(arr) */
                    setEvidence(response.listComplementaryEvidence)
                }
            }
        })
    }, [])

    useEffect(() => {
        if(parseInt(getJwt('rol')) >= 6 ){
            
            if(parseInt(getJwt('rol')) === 6){
                getBranchesByUserRol(parseInt(getJwt('enterprise')), parseInt(getJwt('jtw')), parseInt(getJwt('rol'))).then(response => {
                    if (response.code === 1) {
                        setBranches(response.branches);
                        // console.log('response.branches[0].branchEntId: ',response.branches[0].branchEntId);
                        setBranch(response.branches[0].branchEntId);
                        
                    }
                });
            }else if (access.access.list.length > 0) {
                getBranchesByUserRol(parseInt(getJwt('enterprise')), parseInt(getJwt('jtw')), parseInt(getJwt('rol'))).then(response => {
                    if (response.code === 1) {
                        setBranches(response.branches);
                        // console.log('response.branches[0].branchEntId: ',response.branches[0].branchEntId);
                        setBranch(response.branches[0].branchEntId);
                        
                    }
                });
            }
        }else{
            getBranches(parseInt(getJwt('enterprise'))).then(response => {
                if (response.code === 1) {
                    setBranches(response.branches);
                    setBranch(0);
                }
            });
        }
        // if (access.access.list.length > 0) {
        //     if(parseInt(getJwt('rol')) >= 6 ){
        //         getBranchesByUserRol(parseInt(getJwt('enterprise')), parseInt(getJwt('jtw')), parseInt(getJwt('rol'))).then(response => {
        //             if (response.code === 1) {
        //                 setBranches(response.branches);
        //                 // console.log('response.branches[0].branchEntId: ',response.branches[0].branchEntId);
        //                 setBranch(response.branches[0].branchEntId);
                        
        //             }
        //         });
        //     }else{
        //         getBranches(parseInt(getJwt('enterprise'))).then(response => {
        //             if (response.code === 1) {
        //                 setBranches(response.branches);
        //                 setBranch(0);
        //             }
        //         });
        //     }
        // }
    }, [access])
    

    useEffect(() => {
        getActiveProductsByEnterprise(getJwt("enterprise")).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let acquiredProducts = [];
                        let numAcquiredProducts = 0;
                        for (let x in response.listProducts) {
                            if (response.listProducts[x].acquired === 1) {
                                acquiredProducts.push(response.listProducts[x]);
                                numAcquiredProducts = numAcquiredProducts + 1;
                            }
                        }
                        if (numAcquiredProducts !== 0) {
                            setListProducts(acquiredProducts);
                            if (sessionStorage.getItem("idp-idc")) {
                                let getIds = sessionStorage.getItem("idp-idc");
                                let ids = getIds.split("-");
                                setProductData({
                                    ...productData,
                                    idProduct: parseInt(ids[0]),
                                    idCategory: parseInt(ids[1]),
                                    idSubcategory: parseInt(ids[2])
                                });
                                getAchievementByEnterprise(getJwt("enterprise"), ids[0], ids[1], ids[2], getJwt("jtw"), getJwt("rol")).then((response) => {
                                    if (response) {
                                        switch (response.code) {
                                            case 1:
                                                setListAchieve(response.listAchieve);
                                                /* setCatModules(response.catModules); */
                                                setPercentage(response.percentage);
                                                setThereAreProducts(true);
                                                setLoading(false);
                                                break;
                                            case 1002:
                                                setListAchieve([]);
                                                /* setCatModules([]); */
                                                setPercentage(0);
                                                setThereAreProducts(true);
                                                setNoInformationMessage(true);
                                                setLoading(false);
                                                toastr.info("No cuenta con información por el momento.", "¡Ooops!");
                                                break;
                                            case 1003:
                                                setListAchieve([]);
                                                setPercentage(0);
                                                setThereAreProducts(true);
                                                setNoInformationMessage(true);
                                                setLoading(false);
                                                toastr.info("La organización actual no tiene Centros de Trabajo activados.", "¡Ooops!");
                                                break;
                                            default:
                                                setListAchieve([]);
                                                /* setCatModules([]); */
                                                setPercentage(0);
                                                setThereAreProducts(true);
                                                setNoInformationMessage(true);
                                                setLoading(false);
                                                toastr.error("Ocurrió un error. Intenta nuevamente.", "¡Ooops!");
                                                break;
                                        }
                                    }
                                });
                            } else {
                                setProductData({
                                    ...productData,
                                    idProduct: acquiredProducts[0].idProduct,
                                    idCategory: acquiredProducts[0].idCategory,
                                    idSubcategory: acquiredProducts[0].idSubcategory
                                });
                                getAchievementByEnterprise(getJwt("enterprise"), acquiredProducts[0].idProduct, acquiredProducts[0].idCategory, acquiredProducts[0].idSubcategory, getJwt("jtw"), getJwt("rol")).then((response) => {
                                    if (response) {
                                        switch (response.code) {
                                            case 1:
                                                setListAchieve(response.listAchieve);
                                                /* setCatModules(response.catModules); */
                                                setPercentage(response.percentage);
                                                setThereAreProducts(true);
                                                setLoading(false);
                                                break;
                                            case 1002:
                                                setListAchieve([]);
                                                /* setCatModules([]); */
                                                setPercentage(0);
                                                setThereAreProducts(false);
                                                setLoading(false);
                                                break;
                                            case 1003:
                                                setListAchieve([]);
                                                setPercentage(0);
                                                setThereAreProducts(false);
                                                setLoading(false);
                                                toastr.info("La organización actual no tiene Centros de Trabajo activados.", "¡Ooops!");
                                                break;
                                            default:
                                                setListAchieve([]);
                                                /* setCatModules([]); */
                                                setPercentage(0);
                                                setThereAreProducts(false);
                                                setLoading(false);
                                                toastr.error("Ocurrió un error. Intenta nuevamente.", "¡Ooops!");
                                                break;
                                        }

                                    }
                                });
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    const statusIcon = (cell, row) => {
        if (cell === 1) {
            return (
                <i className="ft-check-circle text-green font-medium-5" />
            )
        } else {
            return (
                <i className="ft-alert-circle text-yellow font-medium-5" />
            )
        }
    }

    /**
     * 
     * @param {*} url 
     */
    const previewFilePDF = (url) => {
        setUrlShowPdf(url);        
        // $('#closeModalViewPdf').show()
    }

    // const showEvidence = (row) => {
    //     let links = row.evidenceList.map(evidence => `${evidence.diffusionDate} - ${evidence.description} <a href=${evidence.urlFile}>descargar</a> <br />`);
    //     links = links.toString().replace(",", "");
    //     Swal.fire({
    //         title: row.title,
    //         html: links,
    //         showCloseButton: true,
    //         // showConfirmButton:false,
    //         confirmButtonText:'Listo!',
    //         confirmButtonColor: '#3085d6',
    //     });
    // }

    const showEvidence2 = (row) => {
        // console.log(row);
        setListDocuments(row.evidenceList)
        setIdAchievement(row.idAchievement)
        $('#documentModalCompliance').show()

    }

    const linkFormat = (cell, row) => {
        const { urlDownload } = row;

        if (urlDownload !== "-") {
            let ext = urlDownload.split('.').pop()
            ext = ext.toLowerCase()
            if (ext === 'pdf') {
                return <i className="bi bi-eye text-blue font-medium-5 pointer" onClick={() => previewFilePDF(urlDownload)} />
            } else {
                return <a href={urlDownload} target="_blank" rel="noreferrer"><i className="ft-download text-blue font-medium-5" /></a>
            }
        } else if (row.evidenceList.length >= 1) {
            return <i className="ft-download text-pink-mine font-medium-5 pointer" onClick={() => showEvidence2(row)} />;
        } else {
            return null;
        }
    }
    
    /**
     * Función para modificar el contenido del row en la tabla
     * @param {any} cell 
     * @param {object} row 
     * @returns 
     */
    const titleFormat = (cell, row) => {
        if (row.idAchievement === 7) { // Valida si es el docuemnto Reporte de resultados
            if ((
                productData.idProduct === 1 &&
                productData.idCategory === 1 &&
                productData.idSubcategory === 2
            ) || (
                    productData.idProduct === 1 &&
                    productData.idCategory === 1 &&
                    productData.idSubcategory === 1
                ) /* && row.urlDownload !== "-" */) { // Valida si es el producto ATS o FRP
                return (
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-6 p-0 d-flex flex-wrap align-content-center'>
                                <span>{cell}</span>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                <div className="form-group m-0">
                                    <select
                                        className="form-control form-control-sm"
                                        id="branches"
                                        onChange={e => setBranch(e.target.value)}
                                        value={branch}
                                        placeholder='Seleccionar'
                                    >
                                        {parseInt(getJwt('rol')) < 6 &&
                                            <option
                                                key={`key-0`}
                                                value={0}
                                            >
                                                Todos los Centros de Trabajo
                                            </option>
                                        }
                                        {
                                            branches.map((branchData) => (
                                                <option
                                                    key={`key-${branchData.branchEntId}`}
                                                    value={branchData.branchEntId}
                                                >
                                                    {branchData.description}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return cell
            }
        } else {
            return cell
        }
    }

    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        noDataText: 'No se encontraron registros.',
    }

    const barOptions = (percentage) => {
        let width = 0;
        let backgroundColor = "#efeff6";
        if (percentage <= 30) {
            //yellow
            width = `${percentage}%`;
            backgroundColor = "#dfa444";
        } else if (percentage > 30 && percentage <= 70) {
            //blue
            width = `${percentage}%`;
            backgroundColor = "#2188b4";
        } else if (percentage > 70) {
            //green
            width = `${percentage}%`;
            backgroundColor = "#9cb537";
        } else {
            //other
            width = "50%";
            backgroundColor = "#71758d";
        }
        return {
            width,
            backgroundColor
        }
    };

    const setOptionText = (nameCategory, nameSubcategory, idSubcategory) => {
        if (idSubcategory === 1) {
            return `${nameSubcategory} (${nameCategory} - Fase 1)`;
        } else if (idSubcategory === 2) {
            return `${nameSubcategory} (${nameCategory} - Fase 2)`;
        } else {
            return `${nameCategory}`;
        }
    }

    /**
     * TODO: Complementary Evidence Handle change product
     * @param {string} ids 
     * @param {string} product 
     * @param {string} category 
     * @param {string} subcategory 
     */
    const handleChangeProduct = (ids, product, category, subcategory) => {
        const getService = ids === '1-1-1' ? 1 : ids === '1-1-2' ? 2 : 3
        setService(getService)
        getComplementaryEvidenceByEnterprise(parseInt(getJwt('enterprise')), parseInt(product), parseInt(category), parseInt(subcategory)).then(response => {
            if (response) {
                if (response.code === 1) {
                    let arr = []
                    response.listComplementaryEvidence.forEach(data => {
                        if (data.resourceType === 1) {
                            arr.push({ ...data, resourceName: 'Archivo' })
                        }
                        if (data.resourceType === 2) {
                            arr.push({ ...data, resourceName: 'Audio' })
                        }
                        if (data.resourceType === 3) {
                            arr.push({ ...data, resourceName: 'Video' })
                        }
                    })
                    setEvidence(arr)
                } else {
                    setEvidence([])
                }
            }
        })
    }

    const handleSelectProductChange = (e) => {
        setLoading(true)
        let product = e.target.value;
        let ids = product.split("-");
        setProductData({
            ...productData,
            idProduct: parseInt(ids[0]),
            idCategory: parseInt(ids[1]),
            idSubcategory: parseInt(ids[2])
        });
        // TODO: Complementary Evidence Handle change product
        handleChangeProduct(product, ids[0], ids[1], ids[2])
        getAchievementByEnterprise(getJwt("enterprise"), ids[0], ids[1], ids[2], getJwt("jtw"), getJwt("rol")).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setListAchieve(response.listAchieve);
                        /* setCatModules(response.catModules); */
                        setPercentage(response.percentage);
                        setNoInformationMessage(false);
                        setLoading(false);
                        break;
                    case 1002:
                        setListAchieve([]);
                        /* setCatModules([]); */
                        setPercentage(0);
                        setNoInformationMessage(true);
                        setLoading(false);
                        break;
                    case 1003:
                        setListAchieve([]);
                        setPercentage(0);
                        setNoInformationMessage(true);
                        setLoading(false);
                        toastr.info("La organización actual no tiene Centros de Trabajo activados.", "¡Ooops!");
                        break;
                    default:
                        setListAchieve([]);
                        /* setCatModules([]); */
                        setPercentage(0);
                        setNoInformationMessage(true);
                        setLoading(false);
                        toastr.error("Ocurrió un error. Intenta nuevamente.", "¡Ooops!");
                        break;
                }
            }
        });
    };
    /* const hashString = (string) => {
        try {
            //let r = Math.random().toString(36).substring(7);
            var enc = window.btoa(string);
            return enc;
        } catch (error) {
            console.log(error);
        }
    }
    const goTo = (idModule) => {
        switch (idModule) {
            case 1:
                sessionStorage.setItem("step", 1);
                sessionStorage.setItem("idp-idc", `${productData.idProduct}-${productData.idCategory}-${productData.idSubcategory}`);
                history.push("/gestion-documental");
                break;
            case 2:
                sessionStorage.setItem("step", 2);
                sessionStorage.setItem("idp-idc", `${productData.idProduct}-${productData.idCategory}-${productData.idSubcategory}`);
                history.push("/gestion-documental");
                break;
            case 3:

                break;
            case 4:
                history.push("/comunicacion");
                break;
            case 5:
                history.push("/guaykipedia");
                break;
            case 6:
                history.push("/resultados");
                break;
            default:
                break;
        }
    } */

    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10">
            {/* <ModalViewPdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf}/> */}
            <ModalView2Pdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf}/>
            <ImagesViewer showImage={showImage} />
            <DocumentModalCompliance setListDocuments={setListDocuments} listDocuments={listDocuments} setUrlShowPdf={setUrlShowPdf} branch={branch} setIdAchievement={setIdAchievement} idAchievement={idAchievement}/>

            <div className="card">
                <div className="card-body">
                    {
                        loading ? <LoaderApp /> :
                        thereAreProducts === true
                            ?
                            (
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                                        <div className="row">
                                            <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
                                                <div className="form-inline font-medium-1">
                                                    <label className="text-base font-weight-bold">Servicio:</label>
                                                    <select
                                                        className="form-control w-75 form-control-sm ml-1"
                                                        id="services"
                                                        onChange={handleSelectProductChange}
                                                        value={productData.idProduct + "-" + productData.idCategory + "-" + productData.idSubcategory}>
                                                        {
                                                            listProducts.map((product) => (
                                                                <option
                                                                    key={product.rank}
                                                                    value={product.idProduct + "-" + product.idCategory + "-" + product.idSubcategory}>{setOptionText(product.nameCategory, product.nameSubcategory, product.idSubcategory)}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className='col-12'>
                                            <h3 className='font-weight-bold'>Documentos Normativos</h3>
                                        </div>
                                    {
                                        noInformationMessage === true
                                            ?
                                            (
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center mt-2">
                                                    <h5 className="font-medium-4 font-weight-lighter font-italic">Sin información por el momento</h5>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="row">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                                        <div className="row d-flex justify-content-center">
                                                            <div className="col-sx-12 col-sm-12 col-md-8 col-lg-8 pt-1">
                                                                <div className="progress">
                                                                    <div
                                                                        className="progress-bar"
                                                                        style={barOptions(percentage)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2 d-flex flex-wrap align-content-center font-medium-1">
                                                                <span className="text-base">Completado <span className="text-blue font-weight-bold">{percentage}%</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                                        <BootstrapTable
                                                            data={listAchieve}
                                                            hover
                                                            pagination={true}
                                                            tableStyle={{ padding: '0px', fontSize: '1.1rem', color: '#6b6f82' }}
                                                            options={options}
                                                        >
                                                            <TableHeaderColumn
                                                                isKey
                                                                dataField="idAchievement"
                                                                hidden></TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField="complete"
                                                                dataAlign="center"
                                                                width="15%"
                                                                tdStyle={{ fontSize: '1.1rem', padding: '5px' }}
                                                                dataFormat={statusIcon}>Estatus</TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="title"
                                                                    width="55%"
                                                                    tdStyle={{ fontSize: '1.1rem', padding: '5px' }}
                                                                    dataFormat={titleFormat}
                                                                >Requerimiento</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField="idModule"
                                                                width="25%"
                                                                tdStyle={{ fontSize: '1.1rem', padding: '5px' }}
                                                                dataAlign="center"
                                                                dataFormat={linkFormat}>Lo encuentras en:</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            )
                            :
                            (
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                        <h5 className="font-medium-4 text-center font-weight-lighter font-italic">Sin información por el momento</h5>
                                    </div>
                                </div>
                            )
                    }
                    {!loading && (
                        <ComEviCtrl
                            service={service}
                            evidence={evidence}
                            setEvidence={setEvidence}
                            disableActions={disableActions}
                            setUrlShowPdf={setUrlShowPdf}
                            setShowImage={setShowImage}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (access) => {
    return {
        access
    }
}

export default connect(mapStateToProps)(Cumplimiento)
