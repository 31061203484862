import React, { useEffect, useRef } from 'react';

const ModalView2Pdf = ({ urlShowPdf, setUrlShowPdf }) => {
  const modalRef = useRef(null); // Referencia al modal
  const isResizing = useRef(false); // Estado de redimensionamiento
  const startX = useRef(0); // Posición inicial X
  const startY = useRef(0); // Posición inicial Y
  const startWidth = useRef(0); // Ancho inicial
  const startHeight = useRef(0); // Alto inicial

  // Cierra el modal
  const closeModalViewPdf = () => {
    setUrlShowPdf("");
  };

  // Inicia el redimensionamiento
  const startResize = (e) => {
    isResizing.current = true;
    startX.current = e.clientX;
    startY.current = e.clientY;
    startWidth.current = modalRef.current.offsetWidth;
    startHeight.current = modalRef.current.offsetHeight;
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', stopResize);
  };

  // Redimensiona el modal
  const resize = (e) => {
    if (isResizing.current) {
      const newWidth = startWidth.current + (e.clientX - startX.current);
      const newHeight = startHeight.current + (e.clientY - startY.current);
      modalRef.current.style.width = `${newWidth}px`;
      modalRef.current.style.height = `${newHeight}px`;
    }
  };

  // Detiene el redimensionamiento
  const stopResize = () => {
    isResizing.current = false;
    document.removeEventListener('mousemove', resize);
    document.removeEventListener('mouseup', stopResize);
  };

  // Agrega el manejador de redimensionamiento al montar el componente
  useEffect(() => {
    const modal = modalRef.current;
    if (modal) {
      const resizeHandle = document.createElement('div');
      resizeHandle.style.position = 'absolute';
      resizeHandle.style.right = '0';
      resizeHandle.style.bottom = '0';
      resizeHandle.style.width = '10px';
      resizeHandle.style.height = '10px';
      resizeHandle.style.backgroundColor = '#ccc';
      resizeHandle.style.cursor = 'se-resize';
      resizeHandle.addEventListener('mousedown', startResize);
      modal.appendChild(resizeHandle);
    }
  }, []);

  // Si no hay URL, no renderizar el modal
  if (!urlShowPdf) return null;

  return (
    <div className="modal-pdf">
      <div
        ref={modalRef}
        className="modal-dialog-pdf"
        style={{
          width: "70%",
          height: "100%",
        }}
      >
        <button className="close-modal-pdf" onClick={closeModalViewPdf}>
          &times;
        </button>
        {/* <div className="modal-content-pdf"> */}
          <iframe className="modal-content-pdf" src={urlShowPdf} title="pdf" style={{width: '100%',height: '100%',border: 'none'}}/>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ModalView2Pdf;