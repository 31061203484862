import React from 'react'
import $ from "jquery"

const ModalViewPdf = ({urlShowPdf,setUrlShowPdf}) => {
    // console.log( typeof urlShowPdf);
    const closeModalViewPdf = () => {
        $('#closeModalViewPdf').hide()
        setUrlShowPdf("")
    }
  return (
    <div
        className="modal"
        id="closeModalViewPdf"
        style={{ backgroundColor: "rgba(255,255,255,0.9)"}}
        data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content" style={{ backgroundColor: 'transparent',  border: "none" }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                        type="button"
                        className="close"
                        onClick={() => closeModalViewPdf()}>&times;</button>
                    </div>
                    <div className="modal-body scrollbar-style-hidden pl-0 pr-0 pt-0" style={{borderRadius: '2rem', overflow: "auto"}}>
                        <iframe 
                            src={urlShowPdf}
                            width="100%" 
                            height="780" 
                            title='pdf'
                            />
                    </div>
                </div>
            </div>
    </div>
  )
}
export default ModalViewPdf;
