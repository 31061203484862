import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { logoutHook } from "../../app/LogOut";
import { useHistory } from "react-router-dom";
import { getJwt, setJwt } from "../../../lib/auth";
import { getuserData } from "./Model";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { getActiveEnterprise } from '../Models/Enterprise_model';
import BannerLogo from './BannerLogo';
import { getBannersByEnterprise } from '../Models/SponsorModel';
import { validationOnEnterpriseChange } from '../../helpers/helpers';
import { connect } from 'react-redux';
import { changeEnterpriseAction, clearGeneralAction } from '../../../redux/generalDuck';
import { isEmptyObject } from 'jquery';
import BannerLogoMobile from '../BannerLogoMobile';
import cookie from 'js-cookie'
import { MenuHover } from '../MenuHover';
const HeaderColaborador = ({ general, changeEnterpriseAction, clearGeneralAction }) => {

    const history = useHistory();
    const [generalData, updateGeneralData] = useState([]);
    const [enterpriseData, updateEnterpriseData] = useState([]);
    const [currentEnterprise, updateCurrentEnterprise] = useState([]);
    const [userInitials, updateUserInitials] = useState("");
    const [logoList, setLogoList] = useState([]);
    const [mobileScreen, setMobileScreen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [userID, setUserID]=useState(0);

    useEffect(() => {
        if(window.location.pathname !== "/encuestas"){
            cookie.remove('survey_usrname', { domain: global.domain });
            cookie.remove('survey_usrid', { domain: global.domain });
            cookie.remove('survey_entname', { domain: global.domain });
            cookie.remove('survey_entid', { domain: global.domain });
            cookie.remove('survey_branches', { domain: global.domain });
            cookie.remove('survey_access', { domain: global.domain });
            cookie.remove('survey_totalcollab', { domain: global.domain });
            // Delete cookies from survey domain
            cookie.remove('survey_usrname', { domain: global.surveyFrontPage });
            cookie.remove('survey_usrid', { domain: global.surveyFrontPage });
            cookie.remove('survey_entname', { domain: global.surveyFrontPage });
            cookie.remove('survey_branches', { domain: global.surveyFrontPage });
            cookie.remove('survey_access', { domain: global.surveyFrontPage });
            cookie.remove('survey_totalcollab', { domain: global.surveyFrontPage });
            cookie.remove('survey_entid', { domain: global.surveyFrontPage });
            cookie.remove('auth_tkn', { domain: global.surveyFrontPage });
        }
        if(window.location.pathname !== "/perfil-empresa"){
            sessionStorage.removeItem('CPV')
        }
      
    }, [])

    useEffect(() => {
        try {
            getuserData(getJwt("jtw")).then((result) => {
                updateGeneralData(result);
                setUserID(result.token);
                const getActiveEnterprises = result.enterprise.filter(enterprise => enterprise.active === 1)
                updateEnterpriseData(getActiveEnterprises);
                if (result.enterprise.length === 0) {
                    updateUserInitials(result.initials);
                } else {
                    for (let index = 0; index < result.enterprise.length; index++) {
                        const element = result.enterprise[index];
                        if (element.enterpriseId === parseInt(getJwt("enterprise"))) {
                            updateCurrentEnterprise(element);
                        }
                    }
                }
                /* if (getJwt("enterpriseName") !== result.name) {
                    for (let index = 0; index < result.enterprise.length; index++) {
                        const element = result.enterprise[index];
                        if (element.enterpriseId === parseInt(getJwt("enterprise"))) {
                            updateCurrentEnterprise(element);
                        }
                    }
                } else {
                    updateUserInitials(result.initials);
                } */
            });
            if (getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined') { }
            else {
                getBannersByEnterprise(getJwt('enterprise'), 1).then(response => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                let index = 0; // Flag
                                let organizer = []; // Array to oraganice objects by 4 elements each cicle
                                let comparative_1 = []; // Comparative array
                                let comparative_2 = []; // Comparative array
                                let structure = []; // Array with objects structure
                                if (window.screen.width <= 600) {
                                    setLogoList(response.resource);
                                }
                                if (window.screen.width <= 992 && window.screen.width > 600) {
                                    setMobileScreen(false)
                                    if (response.resource.length > 3) {
                                        for (let x in response.resource) {
                                            if (index === 3) {
                                                // Fill comparative array with current cicle objects
                                                comparative_1.push(response.resource[x]);
                                                for (let y in organizer) {
                                                    comparative_2.push(organizer[y]);
                                                }
                                                // Set current objects in the structure
                                                structure.push({ adios: '', organizer });
                                                // Clean organizer array
                                                organizer = [];
                                                // Set current object to organizer array
                                                organizer.push(response.resource[x]);
                                                // Set flag
                                                index = 1;
                                            } else {
                                                // Fill comparative array with current cicle objects
                                                comparative_1.push(response.resource[x]);
                                                // Set current object to organizer array
                                                organizer.push(response.resource[x]);
                                                // Set flag
                                                index = index + 1;
                                            }
                                        }
                                        // Clean organizer array
                                        organizer = [];
                                        // Array comparation
                                        let comparativeResult = comparative_1.filter(elemento => comparative_2.indexOf(elemento) === -1);
                                        for (let x in comparativeResult) {
                                            // Set current object to organizer array
                                            organizer.push(comparativeResult[x]);
                                        }
                                        structure.push({ adios: '', organizer });
                                    } else {
                                        for (let x in response.resource) {
                                            // Fill comparative array with current cicle objects
                                            comparative_1.push(response.resource[x]);
                                            // Set current object to organizer array
                                            organizer.push(response.resource[x]);
                                        }
                                        structure.push({ adios: '', organizer });
                                    }
                                    setLogoList(structure);
                                }
                                if (window.screen.width > 1200 && window.screen.width > 992) {
                                    setMobileScreen(false)
                                    if (response.resource.length > 5) {
                                        for (let x in response.resource) {
                                            if (index === 5) {
                                                // Fill comparative array with current cicle objects
                                                comparative_1.push(response.resource[x]);
                                                for (let y in organizer) {
                                                    comparative_2.push(organizer[y]);
                                                }
                                                // Set current objects in the structure
                                                structure.push({ adios: '', organizer });
                                                // Clean organizer array
                                                organizer = [];
                                                // Set current object to organizer array
                                                organizer.push(response.resource[x]);
                                                // Set flag
                                                index = 1;
                                            } else {
                                                // Fill comparative array with current cicle objects
                                                comparative_1.push(response.resource[x]);
                                                // Set current object to organizer array
                                                organizer.push(response.resource[x]);
                                                // Set flag
                                                index = index + 1;
                                            }
                                        }
                                        // Clean organizer array
                                        organizer = [];
                                        // Array comparation
                                        let comparativeResult = comparative_1.filter(elemento => comparative_2.indexOf(elemento) === -1);
                                        for (let x in comparativeResult) {
                                            // Set current object to organizer array
                                            organizer.push(comparativeResult[x]);
                                        }
                                        structure.push({ adios: '', organizer });
                                    } else {
                                        for (let x in response.resource) {
                                            // Fill comparative array with current cicle objects
                                            comparative_1.push(response.resource[x]);
                                            // Set current object to organizer array
                                            organizer.push(response.resource[x]);
                                        }
                                        structure.push({ adios: '', organizer });
                                    }
                                    setLogoList(structure);
                                }
                                break;
                            case 1002:

                                break;
                            default:
                                toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                                break;
                        }
                    } else {
                        toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, []);

    const handleLogout = (event) => {
        event.preventDefault();
        try {
            logoutHook().then(result => {
                if (result === true) {
                    history.push("/");
                    clearGeneralAction();
                }
            })
        } catch (e) {
            alert(e.message);
        }
    }

    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }

    const handleAdminOption = () => {
        if (getJwt("rol") === "2" || parseInt(getJwt("rol")) > 6) {
            if (getJwt("enterpriseName") !== generalData.name) {
                for (let x in enterpriseData) {
                    if (enterpriseData[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                        if (enterpriseData[x].rolEnterpriseId !== 4) {
                            window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                        } else {
                            history.push("/inicio-empresas");
                        }
                    }
                }
            } else {
                if (enterpriseData.length > 0) {
                    let flag = 0;
                    for (let x in enterpriseData) {
                        if (enterpriseData[x].rolEnterpriseId === 2 || enterpriseData[x].rolEnterpriseId > 6) {
                            if (flag === 0) {
                                let userData = {
                                    id: generalData.token,
                                    enterprise: enterpriseData[x].enterpriseId,
                                    rol: enterpriseData[x].rolEnterpriseId,
                                    help: generalData.help,
                                    tkc: generalData.tk,
                                    email: generalData.correo,
                                    session: generalData.session,
                                    enterpriseName: enterpriseData[x].name,
                                    enterpriseInitials: enterpriseData[x].initials,
                                    business: generalData.business,
                                    userName: generalData.name,
                                    branch: enterpriseData[x].branchEntId,
                                }
                                setJwt(userData);
                                window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                                flag = 1;
                            }
                        } else {
                            if (getJwt("rol") === "2") {
                                window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                            }
                        }
                    }
                } else {
                    history.push("/inicio-empresas");
                }
            }
        }

    }

    const handleMenuOptions = (e) => {
        let id = e.target.id;
        switch (id) {
            case "1":
                if (getJwt("rol") === "1") {
                    history.push("/inicio-usuario");
                } else {
                    history.push("/inicio-colaborador");
                }
                break;
            case "2":
                if (enterpriseData.length > 0) {
                    if (getJwt("enterpriseName") !== generalData.name) {
                        for (let x in enterpriseData) {
                            if (enterpriseData[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                                if (enterpriseData[x].incomplete === 0) {
                                    if (enterpriseData[x].rolEnterpriseId !== 4) {
                                        getActiveEnterprise(getJwt("enterprise")).then((response) => {
                                            switch (response?.code) {
                                                case 1: //empresa activa
                                                    history.push("/inicio-administrador");
                                                    break;
                                                case 1001: //empresa inactiva o no existente
                                                    toastr.info("No se puede acceder debido a que la empresa no se encuentra activa.");
                                                    break;
                                                default://error no documentado
                                                    break;
                                            }
                                        });
                                    } else {
                                        toastr.info("No tienes permiso para acceder en esta sección por esta empresa.");
                                    }
                                } else {
                                    if (sessionStorage.getItem('CPV') === null || sessionStorage.getItem('CPV') === undefined) {
                                        // let message = "Faltan datos de la empresa " + enterpriseData[x].name + " por llenar. Ve a Administrar > Organizaciones > Configuración > Perfil de organización y llena los datos faltantes."
                                        let message = "Faltan datos de la empresa " + enterpriseData[x].name + " por llenar. Da click en este mensaje."
                                        toastr.warning(message, "¡Ooops!",{onclick: function() { handleAdminOption(); }});
                                    } else {
                                        history.push("/inicio-administrador")
                                    }
                                }
                            }
                        }
                    } else {
                        let flag = 0;
                        for (let x in enterpriseData) {
                            if ((enterpriseData[x].rolEnterpriseId === 2 && enterpriseData[x].incomplete === 0) || (enterpriseData[x].rolEnterpriseId === 3 && enterpriseData[x].incomplete === 0) || (enterpriseData[x].rolEnterpriseId >= 6 && enterpriseData[x].incomplete === 0)) {
                                if (flag === 0) {
                                    let enterpriseId = getJwt("enterprise")
                                    if (getJwt("enterprise") === 'undefined') {
                                        enterpriseId = enterpriseData[x].enterpriseId
                                    }
                                    getActiveEnterprise(enterpriseId).then((response) => {
                                        let userData = {
                                            id: generalData.token,
                                            enterprise: enterpriseData[x].enterpriseId,
                                            rol: enterpriseData[x].rolEnterpriseId,
                                            help: generalData.help,
                                            tkc: generalData.tk,
                                            email: generalData.correo,
                                            session: generalData.session,
                                            enterpriseName: enterpriseData[x].name,
                                            enterpriseInitials: enterpriseData[x].initials,
                                            business: generalData.business,
                                            userName: generalData.name,
                                            branch: enterpriseData[x].branchEntId,
                                        }
                                        switch (response?.code) {
                                            case 1: //empresa activa
                                                setJwt(userData);
                                                history.push("/inicio-administrador");
                                                break;
                                            case 1001: //empresa inactiva o no existente
                                                toastr.info("No se puede acceder debido a que la empresa no se encuentra activa.");
                                                break;
                                            default://error no documentado
                                                break;
                                        }
                                    });
                                    flag = 1;
                                }
                            }
                        }
                        if (flag === 0) {
                            toastr.warning("Por favor crea una empresa para acceder a administración o faltan datos de la empresa por llenar.", "¡Ooops!");
                        }
                    }
                } else {
                    toastr.warning("Por favor selecciona una empresa.", "¡Ooops!");
                }
                break;
            case "3":
                if (getJwt("rol") === "2" || parseInt(getJwt("rol")) > 6) {
                    if (getJwt("enterpriseName") !== generalData.name) {
                        for (let x in enterpriseData) {
                            if (enterpriseData[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                                /* if (enterpriseData[x].incomplete === 0) { */
                                    if (enterpriseData[x].rolEnterpriseId !== 4) {
                                        window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                                    } else {
                                        history.push("/inicio-empresas");
                                    }
                                /* } else {
                                    let message = "Faltan datos de la empresa " + enterpriseData[x].name + " por llenar. Ve a Administrar > Organizaciones > Configuración > Perfil de organización y llena los datos faltantes."
                                    toastr.warning(message, "¡Ooops!");
                                } */
                            }
                        }
                    } else {
                        if (enterpriseData.length > 0) {
                            let flag = 0;
                            for (let x in enterpriseData) {
                                if (enterpriseData[x].rolEnterpriseId === 2 || enterpriseData[x].rolEnterpriseId > 6) {
                                    if (flag === 0) {
                                        let userData = {
                                            id: generalData.token,
                                            enterprise: enterpriseData[x].enterpriseId,
                                            rol: enterpriseData[x].rolEnterpriseId,
                                            help: generalData.help,
                                            tkc: generalData.tk,
                                            email: generalData.correo,
                                            session: generalData.session,
                                            enterpriseName: enterpriseData[x].name,
                                            enterpriseInitials: enterpriseData[x].initials,
                                            business: generalData.business,
                                            userName: generalData.name,
                                            branch: enterpriseData[x].branchEntId,
                                        }
                                        setJwt(userData);
                                        window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                                        // history.push("/inicio-empresas");
                                        flag = 1;
                                    }
                                } else {
                                    if (getJwt("rol") === "2") {
                                        window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                                        // history.push("/inicio-empresas");
                                    }
                                }
                            }
                        } else {
                            history.push("/inicio-empresas");
                        }
                    }
                }
                break;
            case "4":
                history.push("/perfil");
                break;
            default:
                break;
        }
    }

    const changeEnterprise = (enterpriseId, rol, enterprise) => {
        validationOnEnterpriseChange(enterprise).then(response => {
            if (response.validation) {
                try {
                    for (let x in enterpriseData) {
                        if (enterpriseData[x].enterpriseId === enterpriseId) {
                            let userData = {
                                id: generalData.token,
                                enterprise: enterpriseId,
                                rol: rol,
                                help: generalData.help,
                                tkc: generalData.tk,
                                email: generalData.correo,
                                session: generalData.session,
                                enterpriseName: enterpriseData[x].name,
                                enterpriseInitials: enterpriseData[x].initials,
                                business: generalData.business,
                                userName: generalData.name,
                                branch: enterpriseData[x].branchEntId,
                            }
                            setJwt(userData);
                            switch (rol) {
                                case 1:
                                    window.location = "inicio-usuario";
                                    break;
                                case 4:
                                    if (window.location.pathname.includes('/perfil')) {
                                        window.location.reload();
                                    } else {
                                        window.location = "inicio-colaborador";
                                    }
                                    break;
                                default:
                                    if (window.location.pathname.includes('/perfil-empresa/')) {
                                        window.location = "/perfil-empresa/" + hashString("guaydigital") + enterprise.enterpriseId + "=" + hashString(enterprise.name);
                                    } else {
                                        window.location.reload();
                                    }
                                    break;
                            }
                        }
                    }
                    changeEnterpriseAction(enterprise);
                    if (window.location.pathname.includes('/perfil-empresa/')) {
                        window.location = "/perfil-empresa/" + hashString("guaydigital") + enterprise.enterpriseId + "=" + hashString(enterprise.name);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                toastr.error(response.errorMessage);
            }

        })
    }

    const returnLogoEnterprise = function (enterprise) {
        if (!isEmptyObject(enterprise)) {
            if (enterprise.logo !== undefined && enterprise.logo !== "") {
                return (
                    <li className="nav-item d-flex flex-wrap align-content-center" >
                        <img alt="Logo de tu organización" className="img-logo-nav" src={enterprise.logo} />
                    </li>
                )
            }
        }
    }
    // eslint-disable-next-line
    const memoReturnLogoEnterprise = useMemo(() => returnLogoEnterprise(general.enterpriseInScope), [general.enterpriseInScope]);

    const filteredEnterprises = enterpriseData.filter(data =>
        data.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        currentEnterprise.enterpriseId !== data.enterpriseId
    );

    return (
        <Fragment>
            <div style={{ height: '50px' }} />
            <nav className="navbar navbar-expand-md navbar-colaborador fixed-top">
                {/* Toggler/collapsibe Button */}
                <button hidden className="navbar-toggler pointer" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* Navbar content */}
                <div className="collapse navbar-collapse d-flex justify-content-between" id="collapsibleNavbar">
                    {
                        logoList.length === 0 ? (
                            <Fragment>
                                {/* <ul className="navbar-nav mr-1">
                                    <li className="nav-item d-none d-md-block" >
                                        <span className="nav-link nav-menu-main menu-toggle hidden-xs text-base font-medium-5 pointer">
                                            <i className="ft-menu" />
                                        </span>
                                    </li>
                                    {memoReturnLogoEnterprise}
                                </ul> */}
                                <div className='dropdown-hover'>
                                    <ul className='navbar-nav'>
                                        <li className="nav-item d-none d-md-block">
                                            <span className="nav-link nav-menu-main hidden-xs text-base font-medium-5 pointer"><i className="ft-menu"></i></span>
                                        </li>
                                        {memoReturnLogoEnterprise}
                                    </ul> 
                                    <MenuHover/>
                                </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item d-flex flex-wrap align-content-center">
                                        <img className="img-logo-nav-2 pointer" src="./../img/logo_guay.png" alt="logo" data-tut="tourColaborador-step-1" id="1" onClick={(e) => handleMenuOptions(e)} />
                                    </li>
                                </ul>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {/* <ul className="navbar-nav mr-1">
                                    <li className="nav-item d-none d-md-block" >
                                        <span className="nav-link nav-menu-main menu-toggle hidden-xs text-base font-medium-5 pointer">
                                            <i className="ft-menu" />
                                        </span>
                                    </li>
                                    {memoReturnLogoEnterprise}
                                </ul> */}
                                <div className='dropdown-hover'>
                                    <ul className='navbar-nav'>
                                        <li className="nav-item d-none d-md-block">
                                            <span className="nav-link nav-menu-main hidden-xs text-base font-medium-5 pointer"><i className="ft-menu"></i></span>
                                        </li>
                                        {memoReturnLogoEnterprise}
                                    </ul> 
                                    <MenuHover/>
                                </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item d-flex flex-wrap align-content-center">
                                        <img className="img-logo-nav-2 pointer" src="./../img/logo_guay.png" alt="logo" data-tut="tourColaborador-step-1" id="1" onClick={(e) => handleMenuOptions(e)} />
                                    </li>
                                </ul>
                                {
                                    getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? (
                                        null
                                    ) : (
                                        <ul className="navbar-nav w-75">
                                            <div className='col-12'>
                                                {
                                                    mobileScreen ? (
                                                        <BannerLogoMobile
                                                            logoList={logoList}
                                                        />
                                                    ) : (
                                                        <BannerLogo
                                                            logoList={logoList}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </ul>
                                    )
                                }
                            </Fragment>
                        )
                    }
                    <ul className="navbar-nav icons-rigth">
                        {/* {
                            getJwt("business") === 'true' ? (
                                <li className="nav-item mr-1 d-flex align-items-center font-medium-3">
                                    <i
                                        className="ft-settings pointer"
                                        onClick={() => history.push("/estatus-solicitud")}
                                    />
                                    <img
                                        className="img-btn-nav pointer"
                                        src="./../img/imagesHeader/iconos_guay_inicio_01.png"
                                        alt="commercial"
                                        data-tut="tourColaborador-step-2"
                                    />
                                </li>
                            ) : (
                                null
                            )
                        } */}
                        {/* <li className="nav-item mr-1 ">
                            <a href="tienda">
                                <img className="img-btn-nav pointer" src="./../img/imagesHeader/iconos_guay_inicio_01.png" alt="shop" data-tut="tourColaborador-step-2" />
                            </a>
                        </li> */}
                        {getJwt("rol") === "3" || getJwt("rol") === "2" || getJwt("rol") === "6" || parseInt(getJwt("rol")) > 6 ?
                            <li className="nav-item mr-1">
                                {/* <img className="img-btn-nav pointer" src="./../img/imagesHeader/iconos_47.png" alt="configuration" id="2" onClick={(e) => handleMenuOptions(e)} data-tut="tourColaborador-step-3" /> */}
                                <button 
                                    className="btn btn-sm btn-green-style3 font-weight-normal m-0 px-1"
                                    id="2" 
                                    style={{height:'20px'}}
                                    onClick={(e) => handleMenuOptions(e)} 
                                    data-tut="tourColaborador-step-3"
                                >Configuración</button>
                            </li>
                            : null
                        }
                        <li className="nav-item dropdown" >
                            <button className="img-btn-nav-3 pointer menu-admin-rigth" id="navbardrop" data-toggle="dropdown" data-tut="tourColaborador-step-4" >{currentEnterprise.initials ? currentEnterprise.initials : userInitials}</button>
                            <div className="dropdown-menu" style={{ left: 'auto', right: '0', minWidth: '250px', background: '#efeff6' }}>
                                <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <span className="icon-circle-2">{currentEnterprise.initials ? currentEnterprise.initials : userInitials}</span>
                                    </div>
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <span>{currentEnterprise.name ? currentEnterprise.name : "¡Hola " + generalData.name + "!"}</span>
                                    </div>
                                    {getJwt("rol") === "2" || parseInt(getJwt("rol")) > 6 ?
                                        <button className="btn-invisible-1 text-center" id="3" onClick={(e) => handleMenuOptions(e)}>Administrar</button>
                                        : null
                                    }
                                    <div className="dropdown-divider divider-1 mb-1"></div>
                                    {userID === 95 ? (
                                        <div className="col-lg-12 p-0 contenedor-scroll-50-vh">
                                            {/* Campo de búsqueda */}
                                            <input
                                                type="text"
                                                placeholder="Buscar organización"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="form-control mb-2"
                                            />

                                            {/* Lista de empresas filtradas */}
                                            {filteredEnterprises.map((data, idx) => (
                                                <div className="col-lg-12 div-btn-1 d-flex pointer" key={idx} onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data)}>
                                                    <div className="flex-fill ml-1 icon-circle-1 d-flex justify-content-center flex-wrap align-content-center">
                                                        <span>{data.initials}</span>
                                                    </div>
                                                    <div className="flex-fill ml-1 menu-option" style={{ overflow: 'hidden' }}>
                                                        {data.name}
                                                    </div>
                                                </div>
                                            ))}

                                            {/* Opción "Mi Perfil" */}
                                            <div className="col-lg-12 div-btn-1 d-flex pointer" id="4" onClick={(e) => handleMenuOptions(e)}>
                                                <div className="ml-1 icon-circle-1 flex-fill d-flex justify-content-center flex-wrap align-content-center">
                                                    <img className="img-responsive-8" src="./../img/imagesHeader/iconos_58.png" alt="guay-digital" />
                                                </div>
                                                <div className="flex-fill ml-1 align-middle" id="4" onClick={(e) => handleMenuOptions(e)}>
                                                    Mi Perfil
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-lg-12 p-0 contenedor-scroll-50-vh">
                                            {enterpriseData.map((data, index) =>
                                                currentEnterprise.enterpriseId === data.enterpriseId ?
                                                    null
                                                    :
                                                    <div className="col-lg-12 div-btn-1 d-flex pointer" key={index} id={data.enterpriseId} onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data)}>
                                                        <div className="ml-1 icon-circle-1 flex-fill d-flex justify-content-center flex-wrap align-content-center"><span>{data.initials}</span></div>
                                                        <div className="ml-1 flex-fill menu-option"><span>{data.name}</span></div>
                                                    </div>
                                            )}
                                            <div className="col-lg-12 div-btn-1 d-flex flex-wrap align-content-center pointer" id="4" onClick={(e) => handleMenuOptions(e)}>
                                                <div className="d-inline-block ml-1 icon-circle-1 d-flex justify-content-center flex-wrap align-content-center" id="4" onClick={(e) => handleMenuOptions(e)}><img className="img-responsive-8" src="./../img/imagesHeader/iconos_58.png" alt="guay-digital" /></div>
                                                <div className="d-inline-block ml-1 align-middle" id="4" onClick={(e) => handleMenuOptions(e)}>Mi Perfil</div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="dropdown-divider divider-1 mt-1"></div>
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <button className="btn btn-style-1" onClick={handleLogout}>Salir</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </Fragment>
    );
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps, { changeEnterpriseAction, clearGeneralAction })(HeaderColaborador);