import React, { useState, useEffect } from 'react'
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { getJwt } from "../../../../lib/auth";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { getAreas, addArea, deleteArea, updateArea, getJobsAreas } from '../../Models/Enterprise_model';
import $ from "jquery";
import { setFinishUpload } from '../../Models/Collaborators_model';
import LoaderApp from '../../LoaderApp';
import TablaCargosAreas from './TablaCargosAreas';
import ModalLoaderGen from '../../ModalLoaderGen';

const AdminAreas = ({ actualizarSteps, setCompletedSteps, setTitleSection }) => {

    const enterpriseId = getJwt("enterprise");

    const [areaId, setareaId] = useState(null);

    const [activeAreas, setactiveAreas] = useState([]);
    const [update, setUpdate] = useState();
    const [viewAreaData, setviewAreaData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [areaInput, setareaInput] = useState("");

    const messageValidation = 
    `<div>
        <p>El nombre del área debe cumplir con las siguientes condiciones:</p>
        <ul>
            <li>No debe tener espacios al inicio o al final.</li>
            <li>No debe tener doble espacio entre palabras.</li>
            <li>Solo se permiten los siguientes caracteres especiales: “. “(punto),”,”(comma), “;”,”:”,”-”,”@”,”$”,“&”,”#”</li>
        </ul>
        <p>Por favor, escribe un nombre de área válido.</p>
    </div>`;

    useEffect(() => {
        setLoading(true);
        getAreas(enterpriseId).then((response) => {
            switch (response.code) {
                case 1:
                    setactiveAreas(response.listAreaJobRel);
                    setLoading(false);
                    break;
                case 200:
                    setactiveAreas(response.listAreaJobRel);
                    setLoading(false);
                    break;
                case 404:
                    toastr.error("No se encontraron áreas", "Error");
                    setLoading(false);
                    break;
                case 1001:
                    toastr.error("No se encontraron áreas activas", "Error");
                    setLoading(false);
                    break;
                case 1002:
                    toastr.error("Su organización no cuenta con áreas activas, valla al módulo correspondiente y agréguelas", "Error");
                    setLoading(false);
                    break;
                default:
                    toastr.error("Recargue la pagina, por favor", "Error");
                    setLoading(false);
                    break;
            }
        });
        return () => {
            setUpdate();
            setareaInput("");
        }
    }, [update, enterpriseId])

    setTitleSection("Administración de Áreas");

    const swalAddArea = () => {
        swal({
            text: "Agregar una nueva área",
            icon: "info",
            input: "text",
            content: "input",
            buttons: true,
        }).then((value) => {
            if (value) {
                value = value.trim();
                handleValidateAreaName(value)
                if(!handleValidateAreaName(value)){
                    Swal.fire({
                        icon: "info",
                        html: messageValidation,
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            swalAddArea();
                        }
                    });
                    return;
                }
                if (value) {
                    setLoading(true);
                    const initialWord = value;
                    const finalWord = initialWord
                        .trim() // Elimina espacios al inicio y final
                        .replace(/\s+/g, " "); // Reemplaza múltiples espacios por uno solo
                    addArea(enterpriseId, finalWord).then((response) => {
                        switch (response.code) {
                            case 200:
                                toastr.success("Área agregada correctamente", "¡Éxito!");
                                setUpdate(1);
                                setLoading(false);
                                break;
                            case 1:
                                toastr.success("Área agregada correctamente", "¡Éxito!");
                                setUpdate(3);
                                setLoading(false);
                                break;
                            case 99:
                                toastr.error("Hubo un problema al guardar su nueva área, intente de nuevo", "¡Error!");
                                setUpdate(3);
                                setLoading(false);
                                break;
                            case 1000:
                                toastr.error("Valide los parámetros de su organización y su área", "¡Error!");
                                setUpdate(3);
                                setLoading(false);
                                break;
                            case 1001:
                                toastr.error("Se perdio su organización :( recargue la pagina", "¡Error!");
                                setUpdate(3);
                                setLoading(false);
                                break;
                            case 1002:
                                toastr.error("El área ya se encuentra registrada", "¡Error!");
                                setUpdate(3);
                                setLoading(false);
                                break;
                            case 1003:
                                Swal.fire({
                                    icon: "info",
                                    html: messageValidation,
                                    confirmButtonText: "Ok",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        swalAddArea();
                                    }
                                });
                                setLoading(false);
                                break;
                            default:
                                toastr.error("Error inesperado", "¡Error!");
                                setUpdate(3);
                                setLoading(false);
                                break;
                        }
                    });
                } else {
                    toastr.info("El campo esta vacío", "Atención")
                }
            } else {
                toastr.info("No se agrego el área", "Atención")
            }
        });
    };

    const swalDeleteArea = (areaObject) => {
        Swal.fire({
            title: '<p class="text-white mb-2">¿Seguro?</p>',
            text: "Una vez eliminado, no se podrá recuperar",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "Si, eliminar",
            reverseButtons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                setLoading(true);
                deleteArea(enterpriseId, areaObject.areaEntId).then((response) => {
                    switch (response.code) {
                        case 1:
                            toastr.success("Área eliminada correctamente", "Ok");
                            setUpdate(2);
                            setLoading(false);
                            break;
                        case 99:
                            toastr.error("Error al eliminar el área", "Error");
                            setLoading(false);
                            break;
                        case 1000:
                            toastr.error("Error en los parámetros", "Error");
                            setLoading(false);
                            break;
                        case 1001:
                            toastr.error("Tu organización no esta :(, recarga la pagina", "Error");
                            setLoading(false);
                            break;
                        case 1002:
                            toastr.error("Esa área ya se encuentra registrada", "Error");
                            setLoading(false);
                            break;
                        case 1003:
                            toastr.error("Esa área no está en nuestros datos", "Error");
                            setLoading(false);
                            break;
                        case 1004:
                            toastr.error("Hay colaboradores asigandos a esta área", "Error");
                            setLoading(false);
                            break;
                        default:
                            toastr.error("Error Inesperado", "Error");
                            setLoading(false);
                            break;
                    }
                })
            } else {
                toastr.info("El área NO se eliminó", "Ojo");
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    };

    const handleIAreaFocus = (index) => {
        $(`#input-${index}`).prop("disabled", false);
        $(`#input-${index}`).focus();
        $(`#area-${index}`).attr("class", "card-header text-center border-bottom font-medium-2");
        $(`#input-${index}`).attr("class", "form-control border-0 text-center");
    };

    const handleIAreaOffFocus = (index) => {
        $(`#input-${index}`).prop("disabled", true);
        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
        setTimeout(() => {
            Swal.fire({
                icon: "info",
                html: messageValidation,
                confirmButtonText: "Ok",
            });
        }, 800);
    };

    const handleIAreaChange = (e) => {
        setareaInput(e.target.value);
    };

    const handleIAreaUpdate = (areaObject, index) => {
        
        if (areaInput !== "") {
            if(!handleValidateAreaName(areaInput)){
                handleIAreaOffFocus(index);
                $(`#input-${index}`).val(areaObject.description);
                return;
            }
            setLoading(true);
            const initialWord = areaInput;
            const finalWord = initialWord
                .trim() // Elimina espacios al inicio y final
                .replace(/\s+/g, " "); // Reemplaza múltiples espacios por uno solo
            updateArea(enterpriseId, areaObject.areaEntId, finalWord).then((response) => {
                switch (response.code) {
                    case 1:
                        toastr.success("Área modificada correctamente", "Éxito");
                        setUpdate(3);
                        setLoading(false);
                        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
                        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
                        break;
                    case 99:
                        toastr.error("Error al modificar el área", "Error");
                        setUpdate(3);
                        setLoading(false);
                        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
                        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
                        break;
                    case 1000:
                        toastr.error("Error en los parámetros", "Error");
                        setUpdate(3);
                        setLoading(false);
                        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
                        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
                        break;
                    case 1001:
                        toastr.error("Tu organización no esta :(, recarga la pagina", "Error");
                        setUpdate(3);
                        setLoading(false);
                        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
                        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
                        break;
                    case 1002:
                        toastr.error("Esa área ya se encuentra registrada", "Error");
                        setUpdate(3);
                        setLoading(false);
                        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
                        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
                        break;
                    case 1003:
                        toastr.error("Esa área no está en nuestros datos", "Error");
                        setUpdate(3);
                        setLoading(false);
                        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
                        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
                        break;
                    case 1004:
                        setLoading(false);
                        handleIAreaOffFocus(index);
                        $(`#input-${index}`).val(areaObject.description);
                        break;
                    default:
                        toastr.error("Error Inesperado", "Error");
                        setUpdate(3);
                        setLoading(false);
                        $(`#area-${index}`).attr("class", "card-header card-header-areas text-center border-bottom font-medium-2");
                        $(`#input-${index}`).attr("class", "form-control border-0 text-center card-input-area");
                        break;
                }
            });
        }
    };

    const handleKeyDownEnter = (e, areaObject, index) => {
        if (e.key === 'Enter') {
            handleIAreaUpdate(areaObject, index);
        }
    };

    const handleClicDataArea = (enterpriseId, areaId) => {
        setareaId(areaId);
        getJobsAreas(enterpriseId, areaId).then((response) => {
            setLoading(true);
            switch (response.code) {
                case 1:
                    setviewAreaData(true);
                    setLoading(false);
                    break;
                case 200:
                    setviewAreaData(true);
                    setLoading(false);
                    break;
                case 1002:
                    toastr.error("No tiene puestos o cargos homologados activos para esta área.", "Error");
                    setLoading(false);
                    break;
                default:
                    setLoading(false);
                    break;
            }
        })
    };

    const setStepBack = () => {
        actualizarSteps(2);
        setCompletedSteps(2);
    };

    const setStep = () => {
        const obj = {
            enterpriseId: getJwt('enterprise'),
            flagGreetingDate: 1,
            idUser: getJwt('jtw'),
            greetingDate: '3000-01-01',
        }
        $('#modalLoaderGen').show();
        setFinishUpload(obj).then((response) => {
            if (response) {
                if (response.code === 1) {
                    let areas = btoa(JSON.stringify(response.objsWithoutUsing.Areas))
                    let branches = btoa(JSON.stringify(response.objsWithoutUsing.Branchs))
                    localStorage.setItem('ar', areas)
                    localStorage.setItem('br', branches)
                    actualizarSteps(4)
                    setCompletedSteps(4)
                    $('#modalLoaderGen').hide();
                }
            } else {
                toastr.error('No se pudo pasar al siguiente paso. Intente nuevamente. Si el problema continua, contacte a soporte.', '¡Ooops!');
                $('#modalLoaderGen').hide();
            }
        })
    }

    const handleValidateAreaName = (text) => {
        const regex = /^(?!\s)[A-Za-z0-9.,;:@$&#\-\u00C0-\u017F]+(?: [A-Za-z0-9.,;:@$&#\-\u00C0-\u017F]+)*(?<!\s)$/;
        if (regex.test(text)) {
            return true;
        } else {
            $("#nameCTValidation").show(); // Muestra el mensaje de validación
            return false;
        }
    };

    if (viewAreaData) {
        if (loading) {
            return (
                <LoaderApp />
            )
        } else {
            return (
                <TablaCargosAreas enterpriseId={enterpriseId} areaId={areaId} setviewAreaData={setviewAreaData} />
            )
        }
    } else {
        if (loading) {
            return (
                <LoaderApp />
            )
        } else {
            return (
                <div className="col-12 bg-white rounded p-2 mb-5">
                    <ModalLoaderGen />
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <span className="font-medium-2">Total de &aacute;reas: <span className="text-base-guay">{activeAreas.length}</span></span>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <button className="btn bg-white-guay font-medium-2" onClick={() => swalAddArea()}>
                                <img className="mr-1" src="./../img/collabImages/iconos_carga_colaboradores-07.png" alt="img-guay" style={{ height: '25px', width: '25px' }} />
                                Agregar &aacute;rea
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                {activeAreas.map((area, i) => {
                                    return (
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-4" key={area.areaEntId}>
                                            <div className="card card-areas my-2">
                                                <div id={`area-${i}`} className="card-header card-header-areas text-center border-bottom font-medium-2">
                                                    <input id={`input-${i}`} type="text" className="form-control border-0 text-center card-input-area" onChange={handleIAreaChange} onKeyDown={(e) => handleKeyDownEnter(e, area, i)} defaultValue={area.description} disabled />
                                                </div>
                                                <div className="card-body text-center">
                                                    <span className="card-title font-medium-2">Total de cargos: <span className="text-base-guay">{area.numJobs}</span></span>
                                                    <p className={area.numJobs >= 1 ? "card-text text-blue pointer font-medium-2" : "card-text text-gray font-medium-2"} onClick={() => handleClicDataArea(enterpriseId, area.areaEntId)}>Ver m&aacute;s...</p>
                                                    <p className="card-text">
                                                        <i className="ft-edit-2 icon-medium-size pointer mx-1" onDoubleClick={() => handleIAreaUpdate(area, i)} onClick={() => handleIAreaFocus(i)}></i>
                                                        <i className="ft-trash-2 icon-medium-size pointer mx-1" onClick={() => swalDeleteArea(area)}></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-blue-3 btn-sm pointer float-left" onClick={() => setStepBack()} >Regresar</button>
                            <button className="btn btn-pink btn-sm pointer float-right" onClick={() => setStep()} >Continuar</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default AdminAreas;