import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { logoutHook } from "../LogOut";
import { useHistory } from "react-router-dom";
import { getJwt, setJwt } from "../../../lib/auth";
import { getuserData } from "./Model";
/* import Slider from "react-rangeslider"; */
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { getActiveEnterprise } from '../Models/Enterprise_model';
import Swal from "sweetalert2";
import $, { isEmptyObject } from 'jquery';
import { validationOnEnterpriseChange } from '../../helpers/helpers';
import { connect } from 'react-redux';
import { changeEnterpriseAction, clearGeneralAction } from '../../../redux/generalDuck';
import { clearLaunch } from '../../../redux/launchDuck';
import { deleteSteps } from '../../helpers/helpers';
import cookie from 'js-cookie'
import { MenuHover } from '../MenuHover';

const HeaderAdministrador = ({ general, changeEnterpriseAction, clearGeneralAction, clearLaunch }) => {

    const history = useHistory();
    const [generalData, updateGeneralData] = useState([]);
    const [enterpriseData, updateEnterpriseData] = useState([]);
    const [currentEnterprise, updateCurrentEnterprise] = useState([]);
    const [userInitials, updateUserInitials] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [userID, setUserID]=useState(0);

    useEffect(() => {
        if(window.location.pathname !== "/encuestas"){
            cookie.remove('survey_usrname', { domain: global.domain });
            cookie.remove('survey_usrid', { domain: global.domain });
            cookie.remove('survey_entname', { domain: global.domain });
            cookie.remove('survey_entid', { domain: global.domain });
            cookie.remove('survey_branches', { domain: global.domain });
            cookie.remove('survey_access', { domain: global.domain });
            cookie.remove('survey_totalcollab', { domain: global.domain });
            // Delete cookies from survey domain
            cookie.remove('survey_usrname', { domain: global.surveyFrontPage });
            cookie.remove('survey_usrid', { domain: global.surveyFrontPage });
            cookie.remove('survey_entname', { domain: global.surveyFrontPage });
            cookie.remove('survey_branches', { domain: global.surveyFrontPage });
            cookie.remove('survey_access', { domain: global.surveyFrontPage });
            cookie.remove('survey_totalcollab', { domain: global.surveyFrontPage });
            cookie.remove('survey_entid', { domain: global.surveyFrontPage });
            cookie.remove('auth_tkn', { domain: global.surveyFrontPage });
        }
        if(window.location.pathname !== "/perfil-empresa"){
            sessionStorage.removeItem('CPV')
        }

    }, [])
    

    useEffect(() => {
        try {
            deleteSteps()
            if (getJwt("rol") !== "2" && getJwt("rol") !== "3" && getJwt("rol") !== "6") {
                if (parseInt(getJwt("rol")) < 6) {
                    history.push("/inicio-colaborador");
                }
            }
            getuserData(getJwt("jtw")).then((result) => {
                updateGeneralData(result);
                setUserID(result.token);
                const getActiveEnterprises = result.enterprise.filter(enterprise => enterprise.active === 1)
                updateEnterpriseData(getActiveEnterprises);
                if (result.enterprise.length > 0 && getJwt("enterpriseName") !== result.name) {
                    for (let index = 0; index < result.enterprise.length; index++) {
                        const element = result.enterprise[index];
                        if (element.enterpriseId === parseInt(getJwt("enterprise"))) {
                            updateCurrentEnterprise(element);
                        }
                    }
                } else {
                    updateUserInitials(result.initials);
                }
            });
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getuserData(getJwt("jtw")).then((result) => {
            updateGeneralData(result);
            setUserID(result.token);
            updateEnterpriseData(result.enterprise);
            if (result.enterprise.length > 0 && getJwt("enterpriseName") !== result.name) {
                for (let index = 0; index < result.enterprise.length; index++) {
                    const element = result.enterprise[index];
                    if (element.enterpriseId === parseInt(getJwt("enterprise"))) {
                        updateCurrentEnterprise(element);
                    }
                }
            } else {
                updateUserInitials(result.initials);
            }
        });
        // eslint-disable-next-line
    }, [general.enterpriseInScope.update]);

    const handleLogout = (event) => {
        event.preventDefault();
        try {
            logoutHook().then(result => {
                if (result === true) {
                    history.push("/");
                    clearGeneralAction();
                    clearLaunch();
                }
            })
        } catch (e) {
            alert(e.message);
        }
    }

    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }
    const handleAdminOption = () => {
        try {
            if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
                window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
            }else{
                for (let x in enterpriseData) {
                    if (enterpriseData[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                        if (enterpriseData[x].rolEnterpriseId !== 4) {
                            window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                        } else {
                            history.push("/inicio-empresas");
                        } 
                    }
                }
            }

        } catch (error) {
            
        }
    }

    const handleMenuOptions = (e) => {
        try {
            let id = e.target.id;
            if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
                Swal.fire({
                    title: '<p class="text-white mb-2">¡Aún no has terminado!</p>',
                    text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusCancel: true,
                    cancelButtonText: 'Cancelar',
                    showConfirmButton: true,
                    confirmButtonText: "Si, salir",
                    reverseButtons: true,
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        sessionStorage.removeItem("inLaunch");
                        sessionStorage.removeItem("isEdition");
                        sessionStorage.removeItem("idProduct");
                        sessionStorage.removeItem("idCategory");
                        sessionStorage.removeItem("idSubcategory");
                        sessionStorage.removeItem("nameCategory");
                        sessionStorage.removeItem("nameSubcategory");
                        sessionStorage.removeItem("idTypeLaunch");
                        sessionStorage.removeItem("descriptionLaunch");
                        sessionStorage.removeItem("flagLaunch");
                        sessionStorage.removeItem("launchDate");
                        sessionStorage.removeItem("idGroup");
                        sessionStorage.removeItem("numCollabSelected");
                        switch (id) {
                            case "1":
                                history.push("/inicio-colaborador");
                                break;
                            case "2":
                                if (enterpriseData.length > 0) {
                                    if (getJwt("enterpriseName") !== generalData.name) {
                                        let enterpriseId = getJwt("enterprise")
                                        if (getJwt("enterprise") === 'undefined') {
                                            enterpriseId = generalData.enterpriseId
                                        }
                                        getActiveEnterprise(enterpriseId).then((response) => {
                                            switch (response?.code) {
                                                case 1: //empresa activa
                                                    history.push("/inicio-administrador");
                                                    break;
                                                case 1001: //empresa inactiva o no existente
                                                    toastr.info("No se puede acceder debido a que la empresa no se encuentra activa.");
                                                    break;
                                                default://error no documentado
                                                    break;
                                            }
                                        });
                                    } else {
                                        let flag = 0;
                                        for (let x in enterpriseData) {
                                            if (enterpriseData[x].rolEnterpriseId === 2) {
                                                if (flag === 0) {
                                                    let userData = {
                                                        id: generalData.token,
                                                        enterprise: enterpriseData[x].enterpriseId,
                                                        rol: enterpriseData[x].rolEnterpriseId,
                                                        help: generalData.help,
                                                        tkc: generalData.tk,
                                                        email: generalData.correo,
                                                        session: generalData.session,
                                                        enterpriseName: enterpriseData[x].name,
                                                        enterpriseInitials: enterpriseData[x].initials,
                                                        business: generalData.business,
                                                        userName: generalData.name,
                                                        branch: enterpriseData[x].branchEntId,
                                                    }
                                                    setJwt(userData);
                                                    let enterpriseId = getJwt("enterprise")
                                                    if (getJwt("enterprise") === 'undefined') {
                                                        enterpriseId = enterpriseData[x].enterpriseId
                                                    }
                                                    getActiveEnterprise(enterpriseId).then((response) => {
                                                        switch (response?.code) {
                                                            case 1: //empresa activa
                                                                history.push("/inicio-administrador");
                                                                break;
                                                            case 1001: //empresa inactiva o no existente
                                                                toastr.info("No se puede acceder debido a que la empresa no se encuentra activa.");
                                                                break;
                                                            default://error no documentado
                                                                break;
                                                        }
                                                    });
                                                    flag = 1;
                                                }
                                            }
                                        }
                                        if (flag === 0) {
                                            toastr.warning("Por favor crea una empresa para acceder a administración.", "¡Ooops!");
                                        }
                                    }
                                } else {
                                    toastr.warning("Primero debes registrar una empresa.", "¡Ooops!");
                                }
                                break;
                            case "3":
                                window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                                // history.push("/inicio-empresas");
                                break;
                            case "4":
                                history.push("/perfil");
                                break;
                            case "6":
                                history.push("/tienda");
                                break;
                            default:
                                break;
                        }
                    }
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            } else {
                switch (id) {
                    case "1":
                        history.push("/inicio-colaborador");
                        break;
                    case "2":
                        if (enterpriseData.length > 0) {
                            if (getJwt("enterpriseName") !== generalData.name) {
                                /* for (let x in enterpriseData) {
                                    if (enterpriseData[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                                        if (enterpriseData[x].incomplete === 0) { */
                                let enterpriseId = getJwt("enterprise")
                                if (getJwt("enterprise") === 'undefined') {
                                    enterpriseId = generalData.enterpriseId
                                }
                                for (let x in enterpriseData) {
                                    if (enterpriseData[x].enterpriseId === parseInt(enterpriseId)) {
                                        if (enterpriseData[x].incomplete === 0) {
                                            if (enterpriseData[x].rolEnterpriseId !== 4) {
                                                getActiveEnterprise(enterpriseId).then((response) => {
                                                    switch (response?.code) {
                                                        case 1: //empresa activa
                                                            history.push("/inicio-administrador");
                                                            break;
                                                        case 1001: //empresa inactiva o no existente
                                                            toastr.info("No se puede acceder debido a que la empresa no se encuentra activa.");
                                                            break;
                                                        default://error no documentado
                                                            break;
                                                    }
                                                });
                                            } else {
                                                toastr.info("No tienes permiso para acceder en esta sección por esta empresa.");
                                            }
                                        } else {
                                            if (sessionStorage.getItem('CPV') === null || sessionStorage.getItem('CPV') === undefined) {
                                                // let message = "Faltan datos de la empresa " + enterpriseData[x].name + " por llenar. Ve a Administrar > Organizaciones > Configuración > Perfil de organización y llena los datos faltantes."
                                                let message = "Faltan datos de la empresa " + enterpriseData[x].name + " por llenar. Da click en este mensaje."
                                                toastr.warning(message, "¡Ooops!",{onclick: function() { handleAdminOption(); }});
                                            } else {
                                                history.push("/inicio-administrador")
                                            }
                                        }
                                    }
                                }
                                /* } else {
                                    let message = "Faltan datos de la empresa " + enterpriseData[x].name + " por llenar. Ingresa a Perfil de organización y llena Datos generales."
                                    toastr.warning(message, "¡Ooops!");
                                }
                            }
                        } */
                            } else {
                                let flag = 0;
                                for (let x in enterpriseData) {
                                    if (enterpriseData[x].rolEnterpriseId === 2 && enterpriseData[x].incomplete === 0) {
                                        if (flag === 0) {
                                            let userData = {
                                                id: generalData.token,
                                                enterprise: enterpriseData[x].enterpriseId,
                                                rol: enterpriseData[x].rolEnterpriseId,
                                                help: generalData.help,
                                                tkc: generalData.tk,
                                                email: generalData.correo,
                                                session: generalData.session,
                                                enterpriseName: enterpriseData[x].name,
                                                enterpriseInitials: enterpriseData[x].initials,
                                                business: generalData.business,
                                                userName: generalData.name,
                                                branch: enterpriseData[x].branchEntId,
                                            }
                                            let enterpriseId = getJwt("enterprise")
                                            if (getJwt("enterprise") === 'undefined') {
                                                enterpriseId = enterpriseData[x].enterpriseId
                                            }
                                            getActiveEnterprise(enterpriseId).then((response) => {
                                                switch (response?.code) {
                                                    case 1: //empresa activa
                                                        setJwt(userData);
                                                        history.push("/inicio-administrador");
                                                        break;
                                                    case 1001: //empresa inactiva o no existente
                                                        toastr.info("No se puede acceder debido a que la empresa no se encuentra activa.");
                                                        break;
                                                    default://error no documentado
                                                        break;
                                                }
                                            });
                                            flag = 1;
                                        }
                                    }
                                }
                                if (flag === 0) {
                                    toastr.warning("Por favor crea una empresa para acceder a administración o faltan datos de la empresa por llenar.", "¡Ooops!");
                                }
                            }
                        } else {
                            toastr.warning("Primero debes registrar una empresa.", "¡Ooops!");
                        }
                        break;
                    case "3":
                        for (let x in enterpriseData) {
                            if (enterpriseData[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                                /* if (enterpriseData[x].incomplete === 0) { */
                                    if (enterpriseData[x].rolEnterpriseId !== 4) {
                                        window.location = "/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name);
                                    } else {
                                        history.push("/inicio-empresas");
                                    }
                                /* } else {
                                    let message = "Faltan datos de la empresa " + enterpriseData[x].name + " por llenar. Ve a Administrar > Organizaciones > Configuración > Perfil de organización y llena los datos faltantes."
                                    toastr.warning(message, "¡Ooops!");
                                } */
                            }
                        }
                        // history.push("/inicio-empresas");
                        break;
                    case "4":
                        history.push("/perfil");
                        break;
                    case "5":
                        history.push("/estatus-solicitud");
                        break;
                    case "6":
                        history.push("/tienda");
                        break;
                    default:
                        break;
                }
            }

        } catch (error) {
            console.log(error);
        }
    }
    const changeEnterprise = (enterpriseId, rol, enterprise) => {
        validationOnEnterpriseChange(enterprise).then(response => {
            if (response.validation) {
                try {
                    if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
                        Swal.fire({
                            title: '<p class="text-white mb-2">¡Aún no has terminado!</p>',
                            text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
                            showCloseButton: true,
                            showCancelButton: true,
                            focusCancel: true,
                            cancelButtonText: 'Cancelar',
                            showConfirmButton: true,
                            confirmButtonText: "Si, salir",
                            reverseButtons: true,
                            dangerMode: true
                        }).then((willDelete) => {
                            if (willDelete.isConfirmed) {
                                sessionStorage.removeItem("inLaunch");
                                sessionStorage.removeItem("isEdition");
                                sessionStorage.removeItem("idProduct");
                                sessionStorage.removeItem("idCategory");
                                sessionStorage.removeItem("idSubcategory");
                                sessionStorage.removeItem("nameCategory");
                                sessionStorage.removeItem("nameSubcategory");
                                sessionStorage.removeItem("idTypeLaunch");
                                sessionStorage.removeItem("descriptionLaunch");
                                sessionStorage.removeItem("flagLaunch");
                                sessionStorage.removeItem("launchDate");
                                sessionStorage.removeItem("idGroup");
                                sessionStorage.removeItem("numCollabSelected");
                                getActiveEnterprise(enterpriseId).then((response) => {
                                    switch (response?.code) {
                                        case 1: //empresa activa
                                            for (let x in enterpriseData) {
                                                if (enterpriseData[x].enterpriseId === enterpriseId) {
                                                    let userData = {
                                                        id: generalData.token,
                                                        enterprise: enterpriseId,
                                                        rol: rol,
                                                        help: generalData.help,
                                                        tkc: generalData.tk,
                                                        email: generalData.correo,
                                                        session: generalData.session,
                                                        enterpriseName: enterpriseData[x].name,
                                                        enterpriseInitials: enterpriseData[x].initials,
                                                        business: generalData.business,
                                                        userName: generalData.name,
                                                        branch: enterpriseData[x].branchEntId,
                                                    }
                                                    setJwt(userData);
                                                    switch (rol) {
                                                        case 1:
                                                            window.location = "inicio-usuario";
                                                            break;
                                                        case 4:
                                                            window.location = "inicio-colaborador";
                                                            break;
                                                        default:
                                                            if(window.location.pathname.includes('/perfil-empresa/')){
                                                                window.location = "/perfil-empresa/" + hashString("guaydigital") + enterprise.enterpriseId + "=" + hashString(enterprise.name);
                                                            }else{
                                                                window.location.reload();
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            break; //empresa inactiva
                                        case 1001:
                                            toastr.info("Esta empresa no se encuentra activa");
                                            break;
                                        default://error no documentado
                                            break;
                                    }
                                });
                            }
                        });
                        $(".swal2-title").addClass("bg-header-swal");
                        $(".swal2-actions").addClass("w-100 justify-content-around");
                        $(".swal2-confirm").removeClass("swal2-styled");
                        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                        $(".swal2-cancel").removeClass("swal2-styled");
                        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                    } else {
                        getActiveEnterprise(enterpriseId).then((response) => {
                            switch (response?.code) {
                                case 1: //empresa activa
                                    for (let x in enterpriseData) {
                                        if (enterpriseData[x].enterpriseId === enterpriseId) {
                                            let userData = {
                                                id: generalData.token,
                                                enterprise: enterpriseId,
                                                rol: rol,
                                                help: generalData.help,
                                                tkc: generalData.tk,
                                                email: generalData.correo,
                                                session: generalData.session,
                                                enterpriseName: enterpriseData[x].name,
                                                enterpriseInitials: enterpriseData[x].initials,
                                                business: generalData.business,
                                                userName: generalData.name,
                                                branch: enterpriseData[x].branchEntId,
                                            }
                                            setJwt(userData);
                                            switch (rol) {
                                                case 1:
                                                    window.location = "inicio-usuario";
                                                    break;
                                                case 4:
                                                    window.location = "inicio-colaborador";
                                                    break;
                                                default:
                                                    if(window.location.pathname.includes('/perfil-empresa/')){
                                                        window.location = "/perfil-empresa/" + hashString("guaydigital") + enterprise.enterpriseId + "=" + hashString(enterprise.name);
                                                    }else{
                                                        window.location.reload();
                                                    }
                                                    break;
                                            }
                                        }
                                    }
                                    break; //empresa inactiva
                                case 1001:
                                    toastr.info("Esta empresa no se encuentra activa");
                                    break;
                                default://error no documentado
                                    break;
                            }
                        });
                    }
                    changeEnterpriseAction(enterprise);
                    if(window.location.pathname.includes('/perfil-empresa/')){
                        window.location = "/perfil-empresa/" + hashString("guaydigital") + enterprise.enterpriseId + "=" + hashString(enterprise.name);
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                toastr.error(response.errorMessage);
            }
        })
    }

    const returnLogoEnterprise = function (enterprise) {
        if (!isEmptyObject(enterprise)) {
            if (enterprise.logo !== undefined && enterprise.logo !== "") {
                return (
                    <li className="nav-item d-flex flex-wrap align-content-center" >
                        <img alt="Logo de tu organización" className="img-logo-nav" src={enterprise.logo} />
                    </li>
                )
            }
        }
    }

    const memoReturnLogoEnterprise = useMemo(() => returnLogoEnterprise(general.enterpriseInScope), [general.enterpriseInScope]);

    const filteredEnterprises = enterpriseData.filter(data =>
        data.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        currentEnterprise.enterpriseId !== data.enterpriseId
    );

    return (
        <Fragment>
            <div style={{ height: '60px' }} />
            <nav className="navbar navbar-expand-md navbar-administrador fixed-top">
                {/* Toggler/collapsibe Button */}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* Navbar content */}
                <div className="collapse navbar-collapse d-flex justify-content-between" id="collapsibleNavbar">
                    {/* <ul className="navbar-nav">
                        <li
                            className="nav-item d-none d-md-block"
                        //  id="7" onClick={(e) => handleMenuOptions(e)} --esta linea estaba comentada
                        >
                            <span className="nav-link nav-menu-main menu-toggle hidden-xs menu-link-nav pointer"><i className="ft-menu"></i></span>
                        </li>
                        {memoReturnLogoEnterprise}
                    </ul> */}
                    <div className='dropdown-hover'>
                        <ul className='navbar-nav'>
                            <li
                                className="nav-item d-none d-md-block"
                            >
                                <span className="nav-link nav-menu-main hidden-xs menu-link-nav pointer"><i className="ft-menu"></i></span>
                            </li>
                            {memoReturnLogoEnterprise}
                        </ul> 
                        <MenuHover/>
                    </div>
                    <ul className="navbar-nav">
                        <li className="nav-item ml-2">
                            <img className="img-logo-nav-2 pointer" src="./../img/logo_guay_blanco.png" alt="logo" id="1" onClick={(e) => handleMenuOptions(e)} />
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        {/* {
                            getJwt("business") === 'true' ? (
                                <li className="nav-item mr-1 d-flex align-items-center font-medium-3">
                                    <i
                                        className="ft-settings pointer text-white"
                                        id="5"
                                        onClick={(e) => handleMenuOptions(e)}
                                    />
                                    <img
                                        className="img-btn-nav pointer"
                                        src="./../img/imagesHeader/iconos_guay_inicio_01.png"
                                        alt="commercial"
                                        data-tut="tourColaborador-step-2"
                                    />
                                </li>
                            ) : (
                                null
                            )
                        } */}
                        {/* <li className="nav-item mr-1">
                            <img
                                className="img-btn-nav pointer"
                                src="./../img/imagesHeader/iconos_guay_inicio_01.png"
                                alt="shop"
                                id="6"
                                onClick={(e) => handleMenuOptions(e)}
                            />
                        </li> */}
                        <li className="nav-item mr-1">
                            {/* <img className="img-btn-nav pointer" src="./../img/imagesHeader/iconos_47.png" alt="configuration" id="2" onClick={(e) => handleMenuOptions(e)} /> */}
                            <button 
                                    className="btn btn-sm btn-white-b-green-style font-weight-normal m-0 px-1"
                                    id="2" 
                                    style={{height:'20px'}}
                                    onClick={(e) => handleMenuOptions(e)} 
                                >Configuración</button>
                        </li>
                        <li className="nav-item dropdown">
                            <button className="img-btn-nav-4 pointer" id="navbardrop" data-toggle="dropdown" >{currentEnterprise.initials ? currentEnterprise.initials : userInitials}</button>
                            <div className="dropdown-menu" style={{ left: 'auto', right: '0', minWidth: '250px', background: '#efeff6' }}>
                                <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <span className="icon-circle-2">{currentEnterprise.initials ? currentEnterprise.initials : userInitials}</span>
                                    </div>
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <span>{currentEnterprise.name ? currentEnterprise.name : null}</span>
                                    </div>
                                    {
                                        getJwt("rol") === "2" || parseInt(getJwt("rol")) > 6
                                            ?
                                            (
                                                <button className="btn-invisible-1 text-center" id="3" onClick={(e) => handleMenuOptions(e)}>Administrar</button>
                                            )
                                            :
                                            (null)
                                    }
                                    <div className="dropdown-divider divider-1 mb-1"></div>
                                    {userID === 95 ? (
                                        <div className="col-lg-12 p-0 contenedor-scroll-50-vh">
                                            {/* Campo de búsqueda */}
                                            <input
                                                type="text"
                                                placeholder="Buscar organización"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="form-control mb-2"
                                            />

                                            {/* Lista de empresas filtradas */}
                                            {filteredEnterprises.map((data, idx) => (
                                                <div className="col-lg-12 div-btn-1 d-flex pointer" key={idx} onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data)}>
                                                    <div className="flex-fill ml-1 icon-circle-1 d-flex justify-content-center flex-wrap align-content-center">
                                                        <span>{data.initials}</span>
                                                    </div>
                                                    <div className="flex-fill ml-1 menu-option" style={{ overflow: 'hidden' }}>
                                                        {data.name}
                                                    </div>
                                                </div>
                                            ))}

                                            {/* Opción "Mi Perfil" */}
                                            <div className="col-lg-12 div-btn-1 d-flex pointer" id="4" onClick={(e) => handleMenuOptions(e)}>
                                                <div className="ml-1 icon-circle-1 flex-fill d-flex justify-content-center flex-wrap align-content-center">
                                                    <img className="img-responsive-8" src="./../img/imagesHeader/iconos_58.png" alt="guay-digital" />
                                                </div>
                                                <div className="flex-fill ml-1 align-middle" id="4" onClick={(e) => handleMenuOptions(e)}>
                                                    Mi Perfil
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-lg-12 p-0 contenedor-scroll-50-vh">
                                            {enterpriseData.map((data, idx) =>
                                                currentEnterprise.enterpriseId === data.enterpriseId ?
                                                    null
                                                    :
                                                    <div className="col-lg-12 div-btn-1 d-flex pointer" key={idx} onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data)}>
                                                        <div className="flex-fill ml-1 icon-circle-1 d-flex justify-content-center flex-wrap align-content-center"><span>{data.initials}</span></div>
                                                        <div className="flex-fill ml-1 menu-option" style={{ overflow: 'hidden' }}>{data.name}</div>
                                                    </div>
                                            )}
                                            <div className="col-lg-12 div-btn-1 d-flex pointer" id="4" onClick={(e) => handleMenuOptions(e)}>
                                                <div className="ml-1 icon-circle-1 flex-fill d-flex justify-content-center flex-wrap align-content-center"><img className="img-responsive-8" src="./../img/imagesHeader/iconos_58.png" alt="guay-digital" /></div>
                                                <div className="flex-fill ml-1 align-middle" id="4" onClick={(e) => handleMenuOptions(e)}>Mi Perfil</div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="dropdown-divider divider-1 mt-1"></div>
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <button className="btn btn-style-1" onClick={handleLogout}>Salir</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </Fragment>
    );
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps, { changeEnterpriseAction, clearGeneralAction, clearLaunch })(HeaderAdministrador);